<template>
  <vx-card
    :title="id === null ? $t('institution_create') : $t('institution_edit')"
  >

    <vs-tabs :color="colorx" v-model="tabIndex">
        <vs-tab label="Instituição">
          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                :class="requiredClass(name)"
                :label="$t('name')"
                name="name"
                v-model="name"
                v-validate="'required|max:191'"
              />
              <span class="text-danger text-sm" v-show="errors.has('name')">{{
                errors.first('name')
              }}</span>
            </div>
          </div>
          <div class="vx-row mb-6">
            <div class="vx-col w-1/2 md:w-4/6 sm:w-9/12 lg:w-10/12">
              <div @click="showOrgGrid = true">
                <vs-input
                  readonly
                  :class="requiredClass(orgId)"
                  :label="$t('organization')"
                  name="organization"
                  v-model="orgName"
                  v-validate:orgId="'required'"
                />
                <span
                  class="text-danger text-sm vx-col"
                  v-show="errors.has('organization_id')"
                  >{{ errors.first('organization_id') }}</span
                >
              </div>
            </div>
            <div class="vx-col w-1/2 md:w-2/6 sm:w-3/12 lg:w-2/12 flex items-end">
              <vs-button
                v-permission="'institutions.organization.grid'"
                icon="search"
                class="w-full"
                type="border"
                @click="showOrgGrid = true">Selecionar</vs-button>
            </div>
          </div>
          <div class="vx-row mb-6"></div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-button
                class="float-right"
                color="primary"
                type="border"
                @click="cancel">{{$t('action.back')}}</vs-button>
              <vs-button
                v-permission="'institutions.create'"
                :disabled="!validateForm"
                class="float-right mr-2"
                @click="create">{{$t('action.save')}}</vs-button>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Usuários" v-if="id">

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                label="E-mail"
                name="email"
                v-model="user.email"
                @input="userEmailChanged"
              />
              <span class="text-danger text-sm" v-show="errors.has('userEmail')">{{
                errors.first('userEmail')
              }}</span>
            </div>
          </div>

          <div class="vx-row mb-6">
            <div class="vx-col w-full">
              <vs-input
                :disabled="attachingUser"
                class="w-full"
                label="Nome"
                name="userName"
                v-model="user.name"
              />
              <span class="text-danger text-sm" v-show="errors.has('userName')">{{
                errors.first('userName')
              }}</span>
            </div>
          </div>

          <div class="vx-row float-right gap-2">
            <div class="vx-col w-full">
              <vs-button
                type="border"
                icon-pack="feather"
                icon="icon-file-plus"
                v-permission="'institution_users.create'"
                class="float-right"
                @click="showUserImportPopup = true"
                >Importar</vs-button
              >
              <vs-button
                :disabled="!(attachingUser || creatingUser) || invalidUser"
                icon-pack="feather"
                icon="icon-plus"
                v-permission="'institution_users.create'"
                class="float-right mr-2"
                @click="addUser"
                >{{ creatingUser? 'Criar e Vincular' : 'Vincular' }}</vs-button
              >
            </div>
          </div>

          <div class="vx-row mb-4">
            <div class="vx-col w-full float-right">

              <label :class="addUserInformationClasses()">
                {{addUserInformationText()}}
              </label>

            </div>
          </div>

          <vs-divider v-if="usersToImport.length > 0">Usuários para importar</vs-divider>

          <vs-table :data="usersToImport" v-if="usersToImport.length > 0" :key="importGridKey">
            <template slot="thead">
              <vs-th width="35%">Nome</vs-th>
              <vs-th width="15%">Email</vs-th>
              <vs-th width="20%">Instituição</vs-th>
              <vs-th width="5%">Grupos</vs-th>
              <vs-th width="15%">Status</vs-th>
              <vs-th width="10%">Ação</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="i" v-for="(tr, i) in data" :class="importClassStatus(tr)">
                <vs-td>{{ tr.name }}</vs-td>
                <vs-td>{{ tr.email }}</vs-td>
                <vs-td>{{ name }}</vs-td>
                <vs-td>{{ tr.roles }}</vs-td>
                <vs-td>{{ importStatusText(tr) }}</vs-td>
                <vs-td>
                  <feather-icon
                    icon="Trash2Icon"
                    svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger cursor-pointer"
                    @click="removeUserToImport(i)"
                  />
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <div class="vx-row float-right gap-2 mt-4 mb-4" v-if="usersToImport.length > 0">
            <div class="vx-col w-full">
              <vs-button
                type="border"
                icon-pack="feather"
                icon="icon-file-plus"
                v-permission="'institution_users.create'"
                class="float-right"
                @click="importUsers"
                >Finalizar importação</vs-button
              >
            </div>
          </div>

          <vs-divider>Usuários vinculados</vs-divider>

          <div class="vx-row">
            <div class="vx-col w-full">
              <Grid
                :key="gridKey"
                :showTotalText="'Total usuários vinculados'"
                :service="service"
                route_name="institution_users"
                :route_grid_path="`/${id}/users/grid`"
                hide_edit="true"
                :delegate="{destroy: () => removeUser}"
                :column_formats="{
                  'pivot.created_at' : (data) => {return $utils.format.date(data)}
                }">
              </Grid>
            </div>
          </div>

        </vs-tab>
    </vs-tabs>


    <vs-popup :title="$t('organizacoes')" :active.sync="showOrgGrid">
      <Grid
        :service="orgService"
        route_name="institutions"
        hide_actions="true"
        :delegate="{itemSelected: () => handleOrgSelection} ">
      </Grid>
    </vs-popup>

    <vs-popup
      :title="$t('importar-usuarios')"
      :active.sync="showUserImportPopup"
      style="overflow-y: auto; z-index: 54000;">
      <div class="flex flex-wrap items-center">
        <ImportExcel
          :exampleUrl="importExampleUrl"
          templateFile="educatena-modelo-importacao-usuarios.xlsx"
          :templateMessage="$t('clique-aqui-baixar-modelo')"
          :onSuccess="onSuccess">
        </ImportExcel>
      </div>
    </vs-popup>
  </vx-card>
</template>

<script>
import InstitutionService from '@/services/api/InstitutionService'
import OrganizationService from '@/services/api/OrganizationService'
import UserService from '@/services/api/UserService'
import ImportExcel from '@/components/excel/ImportExcel'

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import standard from '@/services/standard'
import { _ } from 'vue-underscore'

export default {
  components: {
    vueDropzone: vue2Dropzone,
    ImportExcel
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      name: '',
      orgName: '',
      user: {
        id: null,
        name: '',
        email: '',
        roles: ''
      },
      orgId: null,
      service: null,
      orgService: null,
      userService: null,
      showOrgGrid: false,
      debouncedFindUserByEmail: null,
      foundEmail: null,
      showUserImportPopup: false,
      gridKey: 0,
      importGridKey: 0,
      usersImported: 0,
      usersToImport: [],
      dropzoneOptions: {
        url: this.uploadUrl,
        addRemoveLinks: true,
        headers: { Authorization: `Bearer ${standard.token()}` },
        acceptedFiles: 'text/csv'
      },
    }
  },
  computed: {
    importExampleUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}/storage/templates/educatena-modelo-importacao-usuarios.xlsx`
    },
    validateForm() {
      return !this.isEmpty(this.name) && !this.isEmpty(this.orgId)
    },
    creatingUser() {
      if (this.user) {
        return !!(this.user.email.length > 0 && this.user.id === null)
      }
    },
    attachingUser() {
      if (this.user) {
        return !!(this.user.email.length > 0 && this.user.id !== null)
      }
    },
    invalidUser() {
      return !(
        (this.user.email && this.emailIsValid(this.user.email)) &&
        (this.user.name && this.user.name.length > 0) &&
        (this.foundEmail !== null)
      )
    },
    activeInstitution() {
      return this.$store.getters['institution/active']
    },
  },
  methods: {
    onSuccess(data) {
      if (data && data.results && data.results.length > 0) {
        this.usersToImport = data.results
        console.log('this.usersToImport', this.usersToImport)
        this.usersToImport.forEach(user => {
          user.status = 'W'
        })
      } else {
        this.notifyWarning(this.$vs, 'Nenhum usuário encontrado para importar!')
      }

      this.showUserImportPopup = false
    },
    removeUserToImport(index) {
      this.usersToImport.splice(index, 1)
    },
    addUser() {
      this.$vs.loading()
      if (this.service && this.service.addUser) {
        this.service.addUser(this.id, this.user).then(
          data => {
            this.gridKey++
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, 'Usuário vinculado à instituição com sucesso!')
            this.clearUserData()
          },
          error => {
            this.$vs.loading.close()
          }
        )
      }
    },
    importUsers() {
      this.usersToImport.forEach(user => {
        this.importUser(user)
      })
    },
    importUser(user) {
      if (this.service && this.service.addUser) {
        this.service.addUser(this.id, user).then(
          data => {
            user.status = 'S'
            user.statusText = 'Importado com sucesso'
            this.usersImported++
            this.importGridKey++

            if (this.importGridKey >= this.usersToImport.length) {
              this.gridKey++
            }
          },
          error => {
            let message = ''
            try {
              const errorData = JSON.parse(error.data)
              const messages = JSON.parse(errorData.messages)
              message = messages[0]
            } catch (error) {
              message = 'Erro ao importar este usuário!'
            }

            user.status = 'E'
            user.statusText = message
            this.importGridKey++
          }
        )
      }
    },
    importStatusText(user) {
      if ('statusText' in user) {
        return user.statusText
      }
      return '-'
    },
    importClassStatus(user) {
      switch (user.status) {
      case 'W':
        return ''
      case 'E':
        return 'import-error'
      case 'S':
        return 'import-success'
      default:
        return ''
      }
    },
    removeUser(user) {
      this.$vs.loading()
      if (this.service && this.service.removeUser) {
        this.service.removeUser(this.id, user.id).then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, 'Usuário removido da instituição com sucesso!')
            setTimeout(() => {
              this.gridKey++
            }, 100)
          },
          error => {
            this.$vs.loading.close()
          }
        )
      }
    },
    userEmailChanged() {
      if (this.user && this.user.email && this.emailIsValid(this.user.email)) {
        (this.debouncedFindUserByEmail)()
      } else {
        this.user.id = null
        this.user.name = null
        this.foundEmail = null
      }
    },
    verifyEmail() {
      this.$vs.loading()
      this.userService.findUserByEmailInstitution(this.user.email, this.id).then(
        (user) => {
          this.$vs.loading.close()
          if ('id' in user) {
            this.user = user
            this.foundEmail = true
          } else {
            this.user.id = null
            this.foundEmail = false
          }
        },
        (error) => {
          console.error(error)
          this.$vs.loading.close()
        }
      )
    },
    clearUserData() {
      this.user = {
        id: null,
        name: '',
        email: '',
      }
    },
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdate({
          id: this.id,
          name: this.name,
          organization_id: this.orgId
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, 'Operação realizada com sucesso.')
            this.$router.push(`/institutions/${data.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              'Não foi possível realizar esta operação.'
            )
          }
        )
    },
    cancel() {
      this.$router.push('/institutions')
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.$set(this.$data, 'id', data.id)
          this.$set(this.$data, 'name', data.name)
          this.$set(this.$data, 'orgId', data.organization_id)
          this.orgService.read(this.orgId).then(
            data => {
              this.$set(this.$data, 'orgName', data.name)
              this.$vs.loading.close()
            },
            error => {
              this.$vs.loading.close()
            }
          )
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    handleOrgSelection(org) {
      if (org) {
        this.orgName = org.name
        this.orgId = org.id
        this.showOrgGrid = false
      }
    },
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    },
    addUserInformationText() {
      if (this.attachingUser && this.foundEmail === true) {
        return 'Usuário já cadastrado na base de dados, clique para vincular à instituição.'
      } else if (this.user.email.length === 0) {
        return 'Para vincular um usuário à instituição digite o email no primeiro campo acima.'
      }
      return ''
    },
    addUserInformationClasses() {
      const base = 'text-justify font-bold'
      if (this.attachingUser) {
        return `${base} text-success`
      }
      if (this.creatingUser) {
        return `${base}`
      }
      return `${base} text-warning`
    },
    /** Dropzone **/
    success(file) {
      // this.$refs.fileUploadZone.processQueue()
      const response = JSON.parse(file.xhr.response)
      this.setOptionImage(response)
    },
    sending(file, xhr, formData) {
      formData.append('option_id', this.selectedOptionId)
    },
    removeAllFiles() {
      this.$refs.fileUploadZone.removeAllFiles()
    },
    error(file) {
      if (file.status === 'error') {
        this.notifyError(this.$vs, file.xhr.response)
        this.removeAllFiles()
      }
    },
    added(file) {
      file['key'] = this.getFilenameIndex(file.name)
    },
    complete(file, xhr, formData) {
      this.removeAllFiles()
    },
    getFilenameIndex(name) {
      const res = name.replace(' ', '_')
      return res.replace(/[^0-9a-zA-Z]/g, '')
    },
    checkOptionsGridClass() {
      this.optionsGridClass = `grid gap-4 mt-4 grid-cols-${this.chunkQuantity}`
    },
    googleImageSelected(image, option_id) {
      this.$vs.loading()
      this.mediaService
        .optimizeUrl(option_id, image.link)
        .then(response => {
          this.setOptionImage(response)
          this.$vs.loading.close()
        }, error => {
          this.$vs.loading.close()
        })
    },
    chunkOptions() {
      const chunked = _.chunk(this.options, 3)
      if (chunked[chunked.length - 1].length <= 3) {
        chunked[chunked.length - 1].push({ label: 'new_option' })
      } else {
        chunked.push([{ label: 'new_option' }])
      }
      this.chunkedOptions = chunked
    },
    uploadUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}/api/v1/institutions/${this.id}/list_import_file_user`
    }
  },
  created() {
    this.service = InstitutionService.build(this.$vs)
    this.orgService = OrganizationService.build(this.$vs)
    this.userService = UserService.build(this.$vs)
    this.debouncedFindUserByEmail = this._.debounce(this.verifyEmail, 700)
    if (this.id) {
      this.getData(this.id)
    }
  },
}
</script>

<style lang="scss">
.import-success {
  background-color: $success;
}

.import-error {
  background-color: $warning;
}
</style>
